<template>
    <div id="app">
        <RuleFinder />
    </div>
</template>

<script>
import RuleFinder from '@/components/Mapping/RuleFinder';

export default {
    components: {
        RuleFinder,
    }
}
</script>